<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      hide-overlay
      width="800px"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-container class="pa-0">
        <v-card class="pb-10">
          <form-header
            :title="title"
            :form="form"
            :permissions="permissions"
            :view="view"
            @viewChange="viewChanged"
            @saveForm="saveForm"
            @disableRecord="change_status"
            @newForm="newForm"
            @close="close"
            @cancelForm="cancelForm"
            @edit="view = 'edit'"
            :show-enable-button="false"
            :show-disable-button="false"
          />
          <v-divider />
          <v-card-text>
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              label-width="120px"
              label-position="top"
            >
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="row">
                    <div class="col-lg-3 col-sm-12">
                      <el-form-item label="Event Banner" prop="logo_path">
                        <upload-field
                          size="mini"
                          folder="event_banners"
                          allowedFileTypes="['image/jpeg', 'image/png', 'image/bmp']"
                          :width="180"
                          :height="180"
                          :disabled="view != 'new' && view != 'edit'"
                          v-model="form.logo_path"
                        />
                      </el-form-item>
                    </div>
                    <div class="col-lg-9 col-sm-12">
                      <div class="row">
                        <div class="col-lg-6 col-sm-12">
                          <div class="row">
                            <div class="col-12" v-if="view == 'view'">
                              <el-form-item label="Client" prop="clients_id">
                                <el-input
                                  size="mini"
                                  :disabled="true"
                                  v-model="form.client"
                                ></el-input>
                              </el-form-item>
                            </div>
                            <div class="col-lg-12 col-sm-12">
                              <el-form-item label="English Name" prop="name">
                                <el-input
                                  size="mini"
                                  :disabled="view != 'new' && view != 'edit'"
                                  v-model="form.name"
                                ></el-input>
                              </el-form-item>
                            </div>
                            <div class="col-lg-12 col-sm-12">
                              <el-form-item
                                label="Dhivehi Name"
                                prop="dhivehi_name"
                                style="text-align: right"
                              >
                                <span slot="label" class="thaana">
                                  ދިވެހި ނަން
                                </span>
                                <dhivehi-field
                                  v-model="form.dhivehi_name"
                                  :disabled="view != 'new' && view != 'edit'"
                                />
                              </el-form-item>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <upload-field
                            size="mini"
                            v-if="!empty(form.id) && view == 'view'"
                            folder="event_qr_codes"
                            allowedFileTypes="['image/jpeg', 'image/png', 'image/bmp']"
                            :width="50"
                            :height="50"
                            :disabled="true"
                            :value="'event_qr_codes/' + form.id + '.jpg'"
                          />
                          <el-input
                            size="mini"
                            v-if="!empty(form.id) && view == 'view'"
                            :disabled="true"
                            v-model="event_url"
                          ></el-input>
                          <el-input
                            size="mini"
                            v-if="!empty(form.id) && view == 'view'"
                            :disabled="true"
                            :value="
                              'https://eventsmv.online/exhibitor/' +
                              form.id +
                              '/' +
                              form.slug
                            "
                          ></el-input>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                          <el-form-item
                            label="Event Type"
                            prop="event_types_id"
                          >
                            <select-list
                              :readonly="true"
                              v-model="form.event_types_id"
                              editor="events/event-types"
                              :disabled="view != 'new' && view != 'edit'"
                            />
                          </el-form-item>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                          <el-form-item label="Location" prop="locations_id">
                            <select-list
                              :readonly="true"
                              v-model="form.locations_id"
                              editor="clients/locations"
                              :disabled="view != 'new' && view != 'edit'"
                            />
                          </el-form-item>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                          <el-form-item
                            label="Single Day Event"
                            prop="single_day_event"
                          >
                            <el-checkbox
                              v-model="form.single_day_event"
                              :disabled="view != 'new' && view != 'edit'"
                            />
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--From Date -->
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="From Date" prop="from_date">
                    <el-date-picker
                      size="mini"
                      style="width: 100%; max-width: 200px"
                      value-format="yyyy-MM-dd"
                      :picker-options="futurePickerOptions"
                      @change="from_date_changed"
                      placeholder="Date"
                      class="mr-1"
                      v-model="form.from_date"
                      :disabled="view != 'new' && view != 'edit'"
                    ></el-date-picker>
                  </el-form-item>
                </div>
                <!--To Date -->
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="To Date" prop="to_date">
                    <el-date-picker
                      size="mini"
                      style="width: 100%; max-width: 200px"
                      value-format="yyyy-MM-dd"
                      :picker-options="toDatePickerOptions"
                      @change="to_date_changed"
                      placeholder="Date"
                      class="mr-1"
                      v-model="form.to_date"
                      :disabled="
                        (view != 'new' && view != 'edit') ||
                        form.single_day_event == true
                      "
                    ></el-date-picker>
                  </el-form-item>
                </div>
                <!-- Start Time -->
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Start Time" prop="start_time">
                    <el-time-picker
                      v-model="form.start_time"
                      value-format="HH:mm:ss"
                      placeholder="Start time"
                      :disabled="view != 'new' && view != 'edit'"
                      size="mini"
                    ></el-time-picker>
                  </el-form-item>
                </div>
                <!-- End Time-->
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="End Time" prop="end_time">
                    <el-time-picker
                      v-model="form.end_time"
                      value-format="HH:mm:ss"
                      placeholder="End time"
                      :disabled="view != 'new' && view != 'edit'"
                      size="mini"
                    ></el-time-picker>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item
                    label="Different Timing for Days"
                    prop="different_times"
                  >
                    <el-checkbox
                      v-model="form.different_times"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Is Free Event" prop="free_event">
                    <el-checkbox
                      v-model="form.free_event"
                      @change="add_free_event"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item
                    label="Use Different Fares"
                    prop="use_different_fares"
                  >
                    <el-checkbox
                      v-model="form.use_different_fares"
                      :disabled="
                        (view != 'new' && view != 'edit') || form.free_event
                      "
                    />
                  </el-form-item>
                </div>
                <!--<div class="col-lg-4 col-sm-12">
                    <el-form-item
                      label="Allowed Personalized Codes"
                      prop="allowed_personalized_codes"
                    >
                      <el-checkbox
                        v-model="form.allowed_personalized_codes"
                        :disabled="view != 'new' && view != 'edit'"
                      />
                    </el-form-item>
                  </div>-->
                <div class="col-lg-3 col-sm-12">
                  <el-form-item
                    label="Use Custom Domain"
                    prop="use_custom_domain"
                  >
                    <el-checkbox
                      v-model="form.use_custom_domain"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <el-form-item label="Domain (URL)" prop="custom_domain">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.custom_domain"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <el-form-item label="Header" prop="custom_header">
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="4"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.custom_header"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <el-form-item label="Footer" prop="custom_footer">
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="4"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.custom_footer"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <el-form-item label="Intro Text" prop="intro_text">
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="6"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.intro_text"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <el-form-item
                    label="Booking Confirmation Message"
                    prop="booking_confirmed_message"
                  >
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="6"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.booking_confirmed_message"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <el-form-item
                    label="Pre Event Reminder Message"
                    prop="pre_event_message"
                  >
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="6"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.pre_event_message"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item
                    label="Use SMS Notifications"
                    prop="use_sms_notification"
                  >
                    <el-checkbox
                      v-model="form.use_sms_notification"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item
                    label="Use Email Notifications"
                    prop="use_email_notification"
                  >
                    <el-checkbox
                      v-model="form.use_email_notification"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <el-form-item label="Slug" prop="slug">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.slug"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Tel" prop="tel">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.tel"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Hot Line" prop="hot_line">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.hot_line"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Event Email" prop="email">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.email"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Event Website" prop="event_website">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.event_website"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <el-form-item
                    label="Event Visited Message"
                    prop="event_visited_message"
                  >
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="6"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.event_visited_message"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <el-form-item
                    label="Event Left Message"
                    prop="event_left_message"
                  >
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="6"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.event_left_message"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <el-form-item
                    label="Post Event Message"
                    prop="post_event_message"
                  >
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="6"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.post_event_message"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <v-card v-if="form.use_sms_notification == true">
                    <v-system-bar height="47" color="#aeddff">
                      <h3>SMS Configurations</h3>
                      <v-spacer></v-spacer>
                    </v-system-bar>
                    <v-card-text>
                      <div class="col-lg-12 col-sm-12">
                        <el-form-item label="API Type" prop="sms_api_type">
                          <select-list
                            :readonly="true"
                            v-model="form.sms_api_type"
                            :list="smsTypes"
                            :disabled="view != 'new' && view != 'edit'"
                          />
                        </el-form-item>
                      </div>
                      <div
                        class="col-lg-12 col-sm-12"
                        v-if="
                          form.sms_api_type == 'Rest Api' ||
                          form.sms_api_type == 'Authorization Key'
                        "
                      >
                        <el-form-item
                          label="Rest Api URL"
                          prop="sms_rest_api_url"
                        >
                          <el-input
                            size="mini"
                            :disabled="view != 'new' && view != 'edit'"
                            v-model="form.sms_rest_api_url"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div
                        class="col-lg-12 col-sm-12"
                        v-if="form.sms_api_type == 'oAuth2'"
                      >
                        <el-form-item label="oAuth URL" prop="sms_auth_url">
                          <el-input
                            size="mini"
                            :disabled="view != 'new' && view != 'edit'"
                            v-model="form.sms_auth_url"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div
                        class="col-lg-12 col-sm-12"
                        v-if="form.sms_api_type == 'oAuth2'"
                      >
                        <el-form-item
                          label="Bearer Token"
                          prop="sms_bearer_token"
                        >
                          <el-input
                            size="mini"
                            :disabled="view != 'new' && view != 'edit'"
                            v-model="form.sms_bearer_token"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div
                        class="col-lg-12 col-sm-12"
                        v-if="form.sms_api_type == 'oAuth2'"
                      >
                        <el-form-item label="User Name" prop="sms_user_name">
                          <el-input
                            size="mini"
                            :disabled="view != 'new' && view != 'edit'"
                            v-model="form.sms_user_name"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div
                        class="col-lg-12 col-sm-12"
                        v-if="
                          form.sms_api_type == 'Authorization Key' ||
                          form.sms_api_type == 'oAuth2'
                        "
                      >
                        <el-form-item
                          label="Authorization/Access Token/Key"
                          prop="sms_access_token"
                        >
                          <el-input
                            size="mini"
                            :disabled="view != 'new' && view != 'edit'"
                            v-model="form.sms_access_token"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-lg-12 col-sm-12">
                        <el-form-item label="SMS Sender ID" prop="sender_id">
                          <el-input
                            size="mini"
                            :disabled="view != 'new' && view != 'edit'"
                            v-model="form.sender_id"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <v-card v-if="form.use_email_notification == true">
                    <v-system-bar height="47" color="#aeddff">
                      <h3>Email Configurations</h3>
                      <v-spacer></v-spacer>
                    </v-system-bar>
                    <v-card-text>
                      <div class="col-lg-9 col-sm-12">
                        <el-form-item
                          label="Mail Server Host"
                          prop="mail_server"
                        >
                          <el-input
                            size="mini"
                            :disabled="view != 'new' && view != 'edit'"
                            v-model="form.mail_server"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-lg-6 col-sm-12">
                        <el-form-item
                          label="Mail Server Port"
                          prop="mail_server_port"
                        >
                          <el-input-number
                            size="mini"
                            :controls="false"
                            :disabled="view != 'new' && view != 'edit'"
                            v-model="form.mail_server_port"
                          />
                        </el-form-item>
                      </div>
                      <div class="col-lg-12 col-sm-12">
                        <el-form-item
                          label="Mail User Name"
                          prop="mail_user_name"
                        >
                          <el-input
                            size="mini"
                            :disabled="view != 'new' && view != 'edit'"
                            v-model="form.mail_user_name"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-lg-12 col-sm-12">
                        <el-form-item
                          label="Mail Password"
                          prop="mail_password"
                        >
                          <el-input
                            size="mini"
                            show-password
                            :disabled="view != 'new' && view != 'edit'"
                            v-model="form.mail_password"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-lg-12 col-sm-12">
                        <el-form-item
                          label="Mail Sender Email"
                          prop="mail_sender_email"
                        >
                          <el-input
                            size="mini"
                            :disabled="view != 'new' && view != 'edit'"
                            v-model="form.mail_sender_email"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-lg-12 col-sm-12">
                        <el-form-item
                          label="Mail Sender Name"
                          prop="mail_sender_name"
                        >
                          <el-input
                            size="mini"
                            :disabled="view != 'new' && view != 'edit'"
                            v-model="form.mail_sender_name"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <!-- Dates -->
                <div class="col-lg-12 col-sm-12">
                  <v-card>
                    <v-system-bar height="47" color="#aeddff">
                      <h3>Event Dates</h3>
                      <v-spacer></v-spacer>
                    </v-system-bar>
                    <v-card-text>
                      <el-table :data="form.dates" style="width: 100%">
                        <el-table-column
                          prop="date"
                          label="Date"
                          width="120"
                        ></el-table-column>
                        <el-table-column
                          prop="start_time"
                          label="Start Time"
                          :formatter="colFormatTime"
                          width="150"
                        ></el-table-column>
                        <el-table-column
                          prop="end_time"
                          label="End Time"
                          :formatter="colFormatTime"
                          width="100"
                        ></el-table-column>
                        <el-table-column
                          prop="custom_header"
                          label="Custom Header"
                          :show-overflow-tooltip="true"
                          width="150"
                        ></el-table-column>
                        <el-table-column
                          prop="custom_footer"
                          label="Custom Footer"
                          :show-overflow-tooltip="true"
                          width="150"
                        ></el-table-column>
                        <el-table-column
                          prop="booking_confirmed_message"
                          label="Booking Message"
                          :show-overflow-tooltip="true"
                          width="100"
                        ></el-table-column>
                        <el-table-column fixed="right" label="..." width="40">
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              size="small"
                              @click="viewRecord(scope.row)"
                            >
                              View
                            </el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </v-card-text>
                  </v-card>
                </div>
                <!-- Fares -->
                <div class="col-lg-12 col-sm-12">
                  <v-card>
                    <v-system-bar height="47" color="#aeddff">
                      <h3>Event Fares</h3>
                      <v-spacer></v-spacer>
                    </v-system-bar>
                    <v-card-text>
                      <el-table :data="form.fares" style="width: 100%">
                        <el-table-column
                          prop="fare_name"
                          label="Dare Name"
                        ></el-table-column>
                        <el-table-column
                          prop="sort_order"
                          label="Sort Order"
                          width="100"
                        ></el-table-column>
                        <el-table-column
                          prop="custom_header"
                          label="Custom Header"
                          :show-overflow-tooltip="true"
                          width="150"
                        ></el-table-column>
                        <el-table-column
                          prop="custom_footer"
                          label="Custom Footer"
                          :show-overflow-tooltip="true"
                          width="150"
                        ></el-table-column>
                        <el-table-column
                          prop="booking_confirmed_message"
                          label="Booking Message"
                          :show-overflow-tooltip="true"
                          width="100"
                        ></el-table-column>
                        <el-table-column fixed="right" label="..." width="40">
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              size="small"
                              @click="viewRecord(scope.row)"
                            >
                              View
                            </el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <el-form-item label="Status" prop="status">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.status"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </v-card-text>
          <!--<v-divider />-->
        </v-card>
        <v-overlay :value="loading" :opacity="0.7">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from "vue";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  FormItem,
  Input,
  InputNumber,
  Option,
  Select,
  TimePicker,
} from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Checkbox);
Vue.use(InputNumber);
import FormHeader from "@/components/custom/FormHeader.vue";
import DhivehiField from "@/components/custom/DhivehiField.vue";
import UploadField from "@/components/custom/UploadField.vue";
import SelectList from "@/components/custom/SelectList.vue";
export default {
  watch: {
    show: function () {
      this.showForm = this.show;
      this.permissions = this.perm;
      if (this.showForm) {
        if (this.empty(this.data)) {
          this.newForm();
        } else {
          this.view = "view";
        }
      }
    },
    data: function () {
      if (!this.empty(this.data)) {
        this.getRecord();
      }
    },
    perm: function () {
      this.permissions = this.perm;
    },
  },
  props: {
    data: null,
    title: {
      type: String,
      default: "",
    },
    end_point: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    perm: null,
    popup: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {
    FormHeader,
    DhivehiField,
    UploadField,
    SelectList,
  },
  data() {
    return {
      form: {},
      view: "view",
      event_url: null,
      rules: {
        name: [
          {
            required: true,
            message: "Please enter Event Name",
            trigger: "blur",
          },
        ],
        logo_path: [
          {
            required: true,
            message: "Please select Event Logo",
            trigger: "blur",
          },
        ],
        clients_id: [
          { required: true, message: "Please select Client", trigger: "blur" },
        ],
        event_types_id: [
          {
            required: true,
            message: "Please select Location",
            trigger: "blur",
          },
        ],
        locations_id: [
          { required: true, message: "Please enter Name", trigger: "blur" },
        ],
        from_date: [
          {
            required: true,
            message: "Please enter From Date",
            trigger: "blur",
          },
        ],
        to_date: [
          { required: true, message: "Please enter To Date", trigger: "blur" },
        ],
        start_time: [
          {
            required: true,
            message: "Please enter Start Time",
            trigger: "blur",
          },
        ],
        end_time: [
          { required: true, message: "Please enter End Time", trigger: "blur" },
        ],
        booking_confirmed_message: [
          {
            required: true,
            message: "Please enter Booking Confirmation Message",
            trigger: "blur",
          },
        ],
        intro_text: [
          {
            required: true,
            message: "Please enter Intro Text",
            trigger: "blur",
          },
        ],
        slug: [
          { required: true, message: "Please enter Slug", trigger: "blur" },
        ],
        mail_server: [
          {
            required: true,
            message: "Please enter Mail Server Host",
            trigger: "blur",
          },
        ],
        mail_server_port: [
          {
            required: true,
            message: "Please enter Mail Server Port",
            trigger: "blur",
          },
        ],
        mail_user_name: [
          {
            required: true,
            message: "Please enter Mail User Name",
            trigger: "blur",
          },
        ],
        mail_password: [
          {
            required: true,
            message: "Please enter Mail Password",
            trigger: "blur",
          },
        ],
        mail_sender_email: [
          {
            required: true,
            message: "Please enter Mail Sender Email",
            trigger: "blur",
          },
        ],
        mail_sender_name: [
          {
            required: true,
            message: "Please enter Mail Sender Name",
            trigger: "blur",
          },
        ],
        sms_api_type: [
          {
            required: true,
            message: "Please select SMS Api Type",
            trigger: "blur",
          },
        ],
        sms_rest_api_url: [
          {
            required: true,
            message: "Please enter SMS Rest API URL",
            trigger: "blur",
          },
        ],
        sms_auth_url: [
          {
            required: true,
            message: "Please enter SMS Auth URL",
            trigger: "blur",
          },
        ],
        sms_bearer_token: [
          {
            required: true,
            message: "Please enter SMS Bearer TOken",
            trigger: "blur",
          },
        ],
        sms_user_name: [
          {
            required: true,
            message: "Please enter SMS User Name",
            trigger: "blur",
          },
        ],
        sms_access_token: [
          {
            required: true,
            message: "Please enter SMS Access Token",
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            message: "Please enter Tel",
            trigger: "blur",
          },
        ],
        hot_line: [
          {
            required: true,
            message: "Please enter Hot Line number",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please enter Email",
            trigger: "blur",
          },
        ],
      },
      permissions: [],
      loading: false,
      showForm: false,
      toDatePickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now();
        },
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  methods: {
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      this.loading = true;
      var api = "create";
      if (!this.empty(this.form.id)) api = "update";
      this.api(this.end_point + api, this.form)
        .then((response) => {
          if (response.status == true) {
            this.loading = false;
            this.form = response.data;
            this.view = "view";
            this.showInfo("Record Saved successfully");
            this.$emit("updated");
            if (this.popup) this.close();
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    change_status(enable) {
      var api = "enable";
      if (!enable) api = "disable";
      this.loading = true;
      this.api(this.end_point + api, this.form)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newForm() {
      this.form = {
        id: null,
        clients_id: null,
        logo_path: null,
        logo_byte: null,
        name: null,
        dhivehi_name: null,
        event_types_id: null,
        locations_id: null,
        single_day_event: false,
        from_date: null,
        to_date: null,
        start_time: null,
        end_time: null,
        different_times: false,
        use_different_fares: false,
        require_location: false,
        require_event_url: false,
        allowed_personalized_codes: false,
        different_codes_for_days: false,
        free_event: false,
        use_custom_domain: false,
        custom_domain: null,
        custom_header: null,
        custom_footer: null,
        booking_confirmed_message: null,
        pre_event_message: null,
        intro_text: null,
        event_profile: null,
        use_email_notification: true,
        mail_server: null,
        mail_server_port: null,
        mail_user_name: null,
        mail_password: null,
        mail_sender_email: null,
        mail_sender_name: null,
        use_sms_notification: true,
        sms_api_type: null,
        sms_rest_api_url: null,
        sms_auth_url: null,
        sms_bearer_token: null,
        sms_user_name: null,
        sms_access_token: null,
        sender_id: null,
        slug: null,
        event_visited_message: null,
        event_left_message: null,
        post_event_message: null,
        client_users_id: null,
        tel: null,
        hot_line: null,
        email: null,
        event_website: null,
        dates: [],
        fares: [],
        status: "Active",
      };
      this.view = "new";
    },
    getRecord() {
      let f = {
        id: this.data.id,
      };
      this.loading = true;
      this.api(this.end_point + "record", f)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
          this.event_url = response.event_url;
          this.permissions = response.permissions;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
    cancelForm() {
      this.view = "view";
      this.close();
    },
    viewChanged(val) {
      this.view = val;
    },
    from_date_changed() {
      if (this.form.single_day_event == true) {
        this.form.to_date = new Date(this.form.from_date);
        this.toDatePickerOptions = {
          disabledDate(time) {
            return time.getTime() < Date.now();
          },
          shortcuts: [
            {
              text: "Today",
              onClick(picker) {
                picker.$emit("pick", new Date());
              },
            },
            {
              text: "Yesterday",
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24);
                picker.$emit("pick", date);
              },
            },
            {
              text: "A week ago",
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", date);
              },
            },
          ],
        };
        let f = {
          clients_id: this.form.clients_id,
          events_id: null,
          date: this.form.from_date,
          start_time: null,
          end_time: null,
          max_capacity: 0,
          custom_header: null,
          custom_footer: null,
          booking_confirmed_message: null,
          client_users_id: null,
        };
        this.form.dates.push(f);
      } else {
        let self = this;
        this.toDatePickerOptions = {
          disabledDate(time) {
            return time.getTime() <= new Date(self.form.from_date);
          },
          shortcuts: [
            {
              text: "Today",
              onClick(picker) {
                picker.$emit("pick", new Date());
              },
            },
            {
              text: "Yesterday",
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24);
                picker.$emit("pick", date);
              },
            },
            {
              text: "A week ago",
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", date);
              },
            },
          ],
        };
        /*this.form.to_date = new Date(
            this.addDays(new Date(this.form.from_date), 1)
          );*/
      }
    },
    to_date_changed() {
      this.form.dates = [];
      let fd = new Date(this.form.from_date);
      let td = new Date(this.form.to_date);
      console.log(this.form.start_time);
      while (fd <= td) {
        let f = {
          id: null,
          clients_id: this.form.clients_id,
          events_id: !this.empty(this.form.id) ? this.form.id : null,
          date: this.formatDate(fd),
          start_time: !this.empty(this.form.start_time)
            ? this.formatDate(this.form.start_time, "HH:mm:ss")
            : null,
          end_time: !this.empty(this.form.end_time)
            ? this.formatDate(this.form.end_time, "HH:mm:ss")
            : null,
          max_capacity: 0,
          custom_header: this.form.custom_header,
          custom_footer: this.form.custom_footer,
          booking_confirmed_message: this.form.booking_confirmed_message,
          client_users_id: null,
        };
        this.form.dates.push(f);
        fd.setDate(fd.getDate() + 1);
        this.$forceUpdate();
      }
    },
    add_free_event() {
      this.form.fares = [];
      if (this.form.free_event == true) {
        let f = {
          id: null,
          clients_id: this.form.clients_id,
          events_id: this.form.id,
          fare_name: "FREE",
          sort_order: 0,
          rate: 0,
          custom_header: this.form.custom_header,
          custom_footer: this.form.custom_footer,
          booking_confirmed_message: this.form.booking_confirmed_message,
          client_users_id: null,
          status_id: 1,
        };
        this.form.fares.push(f);
      }
    },
  },
  name: "EventsForm",
};
</script>
